.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.channel-join-wrapper {
  padding-top: 90px;
}
.channel-join-wrapper .banner-container {
  box-sizing: border-box;
  height: 35.41666666666667vw;
  padding-top: 10.989583333333334vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16269412400782824.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.channel-join-wrapper .banner-container .info {
  margin-left: 12.5vw;
}
.channel-join-wrapper .banner-container .info h1.title {
  margin-bottom: 1.1458333333333333vw;
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.channel-join-wrapper .banner-container .info .desc {
  margin-bottom: 3.125vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}
.channel-join-wrapper .banner-container .info .btn {
  display: block;
  width: 14.583333333333334vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  background-color: #fff;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6600;
  text-decoration: none;
  cursor: pointer;
}
.channel-join-wrapper .banner-container .info .btn:hover {
  background-color: #ff6600;
  color: #fff;
}
.channel-join-wrapper .search-container {
  box-sizing: border-box;
  height: 51.354166666666664vw;
  padding-top: 5.729166666666666vw;
  background-color: #fff;
}
.channel-join-wrapper .search-container .search-box {
  width: 48.4375vw;
  margin: 0 auto 5.83333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4166666666666667vw;
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.1);
}
.channel-join-wrapper .search-container .search-box .search-input {
  box-sizing: border-box;
  width: 41.66666666666667vw;
  height: 4.166666666666666vw;
  padding: 0 1.5625vw;
  border: none;
  outline: none;
  border-top-left-radius: 0.4166666666666667vw;
  border-bottom-left-radius: 0.4166666666666667vw;
  font-size: 1.0416666666666665vw;
  color: #333;
}
.channel-join-wrapper .search-container .search-box .search-input::placeholder {
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999;
}
.channel-join-wrapper .search-container .search-box .btn {
  width: 6.770833333333333vw;
  height: 4.166666666666666vw;
  line-height: 4.166666666666666vw;
  text-align: center;
  background-color: #ff6600;
  border-top-right-radius: 0.4166666666666667vw;
  border-bottom-right-radius: 0.4166666666666667vw;
  font-size: 1.0416666666666665vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.channel-join-wrapper .search-container > .title {
  margin-bottom: 4.53125vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.channel-join-wrapper .search-container .content-box {
  display: flex;
  align-items: center;
  margin-left: 12.5vw;
}
.channel-join-wrapper .search-container .content-box .info {
  margin-right: 8.90625vw;
}
.channel-join-wrapper .search-container .content-box .info .item {
  display: flex;
  align-items: center;
  margin-bottom: 2.7083333333333335vw;
}
.channel-join-wrapper .search-container .content-box .info .item .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-right: 0.9895833333333333vw;
}
.channel-join-wrapper .search-container .content-box .info .item .right .title {
  margin-bottom: 0.46875vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.channel-join-wrapper .search-container .content-box .info .item .right .desc {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.channel-join-wrapper .search-container .content-box .info .btn {
  display: block;
  width: 14.583333333333334vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #ff6600;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.channel-join-wrapper .search-container .content-box .info .btn:hover {
  font-weight: 600;
  text-shadow: 0px 7px 21px rgba(255, 102, 0, 0.4);
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}
.channel-join-wrapper .search-container .content-box .image {
  position: relative;
  width: 35.41666666666667vw;
  height: 23.229166666666668vw;
  border-radius: 0.8333333333333334vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16269435920767640.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.channel-join-wrapper .search-container .content-box .image .left,
.channel-join-wrapper .search-container .content-box .image .right {
  position: absolute;
  width: 10.46875vw;
  height: 12.34375vw;
}
.channel-join-wrapper .search-container .content-box .image .left {
  left: -5.104166666666667vw;
  bottom: 2.34375vw;
}
.channel-join-wrapper .search-container .content-box .image .right {
  right: -4.21875vw;
  top: 2.65625vw;
}
.channel-join-wrapper .service-container {
  box-sizing: border-box;
  height: 44.79166666666667vw;
  padding-top: 4.84375vw;
  background: url(https://mktv-in-cdn.mockuai.com/16275719108767876.png) left top / cover no-repeat, linear-gradient(133deg, #00092a 0%, #001554 100%);
}
.channel-join-wrapper .service-container > .title {
  margin-bottom: 5vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.channel-join-wrapper .service-container .service-list {
  display: flex;
  align-items: center;
  margin: 0 12.5vw;
}
.channel-join-wrapper .service-container .service-list .service-item {
  position: relative;
  width: 14.166666666666666vw;
  height: 18.75vw;
  margin-right: 1.0416666666666665vw;
  background-color: #fff;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
}
.channel-join-wrapper .service-container .service-list .service-item:last-child {
  margin-right: 0;
}
.channel-join-wrapper .service-container .service-list .service-item .detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.channel-join-wrapper .service-container .service-list .service-item .detail .icon {
  width: 3.75vw;
  height: 3.75vw;
  margin-bottom: 0.8854166666666666vw;
}
.channel-join-wrapper .service-container .service-list .service-item .detail .title {
  margin-bottom: 0.5208333333333333vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.channel-join-wrapper .service-container .service-list .service-item .detail .desc {
  width: 9.375vw;
  text-align: center;
  line-height: 1.0416666666666665vw;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.channel-join-wrapper .service-container .service-list .service-item .cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00092a;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.channel-join-wrapper .service-container .service-list .service-item .cover .text {
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.channel-join-wrapper .service-container .btn {
  display: block;
  margin: 5.20833333vw auto 0;
  width: 14.583333333333334vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}
.channel-join-wrapper .service-container .btn:hover {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  background-color: #ff6600;
  color: #fff;
}
.channel-join-wrapper .products-container {
  box-sizing: border-box;
  height: 48.541666666666664vw;
  padding-top: 4.791666666666667vw;
  background-color: #fff;
}
.channel-join-wrapper .products-container > .title {
  margin-bottom: 4.6875vw;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  text-align: center;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.channel-join-wrapper .products-container .products-box {
  margin: 0 12.5vw;
  display: flex;
  align-items: center;
}
.channel-join-wrapper .products-container .products-box .product-item {
  position: relative;
  width: 23.958333333333336vw;
  height: 31.25vw;
  margin-right: 1.5625vw;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #001554;
}
.channel-join-wrapper .products-container .products-box .product-item:last-child {
  margin-right: 0;
}
.channel-join-wrapper .products-container .products-box .product-item.app-anchor {
  background-image: url(https://mktv-in-cdn.mockuai.com/16270073947208399.png);
}
.channel-join-wrapper .products-container .products-box .product-item.app-store {
  background-image: url(https://mktv-in-cdn.mockuai.com/16270073947997630.png);
}
.channel-join-wrapper .products-container .products-box .product-item.scrm {
  background-image: url(https://mktv-in-cdn.mockuai.com/16270073948081185.png);
}
.channel-join-wrapper .products-container .products-box .product-item .info {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 20.729166666666668vw;
  padding: 0 2.08333333vw 1.5625vw;
}
.channel-join-wrapper .products-container .products-box .product-item .info .desc {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  margin-bottom: 0.8333333333333334vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.channel-join-wrapper .products-container .products-box .product-item .info .points {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.channel-join-wrapper .products-container .products-box .product-item .info .points .point {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  margin-bottom: 0.78125vw;
}
.channel-join-wrapper .products-container .products-box .product-item .info .points .point .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.4166666666666667vw;
}
.channel-join-wrapper .products-container .products-box .product-item .info .points .point .text {
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.channel-join-wrapper .products-container .products-box .product-item .name {
  visibility: visible;
  position: absolute;
  left: 2.083333333333333vw;
  bottom: 1.7708333333333333vw;
  font-size: 1.6666666666666667vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.channel-join-wrapper .products-container .products-box .product-item:hover {
  background-color: transparent;
}
.channel-join-wrapper .products-container .products-box .product-item:hover.app-anchor {
  background-image: url(https://mktv-in-cdn.mockuai.com/16270097428527591.png);
}
.channel-join-wrapper .products-container .products-box .product-item:hover.app-store {
  background-image: url(https://mktv-in-cdn.mockuai.com/16270097429057388.png);
}
.channel-join-wrapper .products-container .products-box .product-item:hover.scrm {
  background-image: url(https://mktv-in-cdn.mockuai.com/16270097429117888.png);
}
.channel-join-wrapper .products-container .products-box .product-item:hover .name {
  visibility: hidden;
}
.channel-join-wrapper .products-container .products-box .product-item:hover .info {
  visibility: visible;
}
.channel-join-wrapper .map-container {
  box-sizing: border-box;
  height: 67.60416666666667vw;
  padding-top: 4.791666666666667vw;
  background: url(https://mktv-in-cdn.mockuai.com/16270101557082016.png) top left / 100% 67.60416667vw no-repeat, linear-gradient(133deg, #00092a 0%, #001554 100%);
}
.channel-join-wrapper .map-container > .title {
  margin-bottom: 1.7187500000000002vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.channel-join-wrapper .map-container .map {
  display: block;
  margin: 0 auto;
  width: 69.6875vw;
}
.channel-join-wrapper .apply-container {
  box-sizing: border-box;
  height: 27.8125vw;
  padding-top: 4.791666666666667vw;
}
.channel-join-wrapper .apply-container > .title {
  margin-bottom: 6.197916666666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.channel-join-wrapper .apply-container .process-box {
  display: flex;
  align-items: center;
  margin: 0 18.75vw;
}
.channel-join-wrapper .apply-container .process-box .process-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12.5vw;
}
.channel-join-wrapper .apply-container .process-box .process-item::after {
  content: '';
  position: absolute;
  right: -11.25vw;
  top: 2.604166666666667vw;
  width: 9.895833333333332vw;
  height: 1.0416666666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16270107918162801.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.channel-join-wrapper .apply-container .process-box .process-item:last-child {
  margin-right: 0;
}
.channel-join-wrapper .apply-container .process-box .process-item:last-child::after {
  content: '';
  width: 0;
  height: 0;
}
.channel-join-wrapper .apply-container .process-box .process-item .icon-box {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.25vw;
  height: 6.25vw;
  margin-bottom: 1.0416666666666665vw;
  border: 1px solid #fff;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.06);
}
.channel-join-wrapper .apply-container .process-box .process-item .icon-box .icon {
  width: 3.75vw;
  height: 3.75vw;
}
.channel-join-wrapper .apply-container .process-box .process-item .text {
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.channel-join-wrapper .form-container {
  box-sizing: border-box;
  min-height: 57.291666666666664vw;
  padding-top: 4.791666666666667vw;
  padding-bottom: 5.104166666666667vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16270126763335253.png);
  background-position: bottom right;
  background-size: 30.36458333vw 34.11458333vw;
  background-repeat: no-repeat;
}
.channel-join-wrapper .form-container > .title {
  margin-bottom: 3.5937499999999996vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.channel-join-wrapper .form-container .form-box {
  margin: 0 18.75vw;
}
.channel-join-wrapper .form-container .form-box .el-form-item {
  margin-right: 0vw;
  margin-bottom: 1.9791666666666665vw;
}
.channel-join-wrapper .form-container .form-box .el-form-item /deep/ .el-form-item__label {
  padding-bottom: 0.5208333333333333vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #333333;
}
.channel-join-wrapper .form-container .form-box .el-form-item /deep/ .el-form-item__label::before {
  content: '';
  margin-right: 0;
}
.channel-join-wrapper .form-container .form-box .el-select /deep/ input,
.channel-join-wrapper .form-container .form-box .el-cascader /deep/ input,
.channel-join-wrapper .form-container .form-box .el-input /deep/ input {
  box-sizing: border-box;
  height: 2.7083333333333335vw;
  line-height: 2.7083333333333335vw;
}
.channel-join-wrapper .form-container .form-box .el-select /deep/ input::placeholder,
.channel-join-wrapper .form-container .form-box .el-cascader /deep/ input::placeholder,
.channel-join-wrapper .form-container .form-box .el-input /deep/ input::placeholder {
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #999999;
}
.channel-join-wrapper .form-container .form-box .el-select /deep/ input:focus,
.channel-join-wrapper .form-container .form-box .el-cascader /deep/ input:focus,
.channel-join-wrapper .form-container .form-box .el-input /deep/ input:focus {
  outline: none;
  border: 1px solid #ff6600;
}
.channel-join-wrapper .form-container .form-box .el-select {
  width: 62.5vw;
}
.channel-join-wrapper .form-container .form-box .el-input {
  width: 30.208333333333332vw;
}
.channel-join-wrapper .form-container .form-box .learn {
  width: 62.5vw;
}
.channel-join-wrapper .form-container .form-box .learn_item {
  margin-bottom: 1.0416666666666665vw;
}
.channel-join-wrapper .form-container .form-box .el-cascader {
  width: 62.5vw;
}
.channel-join-wrapper .form-container .form-box .el-textarea {
  width: 62.5vw;
}
.channel-join-wrapper .form-container .form-box .el-textarea /deep/ textarea {
  height: 6.25vw;
}
.channel-join-wrapper .form-container .form-box .el-textarea /deep/ textarea::placeholder {
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #999999;
}
.channel-join-wrapper .form-container .form-box .el-textarea /deep/ textarea:focus {
  outline: none;
  border: 1px solid #ff6600;
}
.channel-join-wrapper .form-container .form-box .submit-btn {
  margin: 3.125vw auto 0;
  width: 12.083333333333334vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #ff6600;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.channel-join-wrapper .form-container .form-box .submit-btn:hover {
  font-weight: 600;
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}
.channel-join-wrapper .form-container .form-box .tips {
  margin-top: 1.875vw;
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.channel-join-wrapper .result-modal {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.channel-join-wrapper .result-modal .modal-box {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.20833333333333334vw;
  background-color: #fff;
}
.channel-join-wrapper .result-modal .close-icon {
  position: absolute;
  right: 1.0416666666666665vw;
  top: 1.0416666666666665vw;
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  cursor: pointer;
}
.channel-join-wrapper .result-modal .btn {
  margin: 2.60416667vw auto 0;
  width: 8.333333333333332vw;
  height: 2.2916666666666665vw;
  line-height: 2.2916666666666665vw;
  background-color: #ff6600;
  border-radius: 1.5625vw;
  text-align: center;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.channel-join-wrapper .result-modal.apply-success .modal-box {
  width: 37.5vw;
  height: 23.28125vw;
  padding: 2.86458333vw 2.08333333vw;
}
.channel-join-wrapper .result-modal.apply-success .modal-box .title {
  margin-bottom: 1.5625vw;
  text-align: center;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.channel-join-wrapper .result-modal.apply-success .modal-box .text {
  margin-bottom: 0.8333333333333334vw;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.channel-join-wrapper .result-modal.apply-success .modal-box .content {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 33.33333333333333vw;
  padding: 1.0416666666666665vw;
  border-radius: 0.20833333333333334vw;
  background-color: #f8f8f8;
}
.channel-join-wrapper .result-modal.apply-success .modal-box .content .item {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  margin-bottom: 0.7291666666666666vw;
}
.channel-join-wrapper .result-modal.apply-success .modal-box .content .item.item-line {
  flex: 0 0 100%;
}
.channel-join-wrapper .result-modal.apply-success .modal-box .content .item .label,
.channel-join-wrapper .result-modal.apply-success .modal-box .content .item .detail {
  margin-right: 0.4166666666666667vw;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.channel-join-wrapper .result-modal.search-result .modal-box {
  width: 32.29166666666667vw;
  height: 15.885416666666666vw;
  padding-top: 2.864583333333333vw;
}
.channel-join-wrapper .result-modal.search-result .modal-box .title {
  margin-bottom: 1.5625vw;
  text-align: center;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.channel-join-wrapper .result-modal.search-result .modal-box .result {
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
